import React from 'react';
import Autolinker from 'autolinker';
import GlobalStateContext from "../GlobalStateContext";

export default class TextContent extends React.Component {
    static contextType = GlobalStateContext;
    render() {
        let textColumnClassName = "col-12 col" + (this.props.textColumnWidth && this.props.textColumnWidth > 0 ? `-md-${this.props.textColumnWidth}` : "");
        let mediaColumnClassName = "col-12 col" + (this.props.mediaColumnWidth && this.props.mediaColumnWidth > 0 ? `-md-${this.props.mediaColumnWidth}` : "");
        let justifyContentProperty = (this.props.textColumnWidth && this.props.textColumnWidth > 0 && this.props.mediaColumnWidth && this.props.mediaColumnWidth > 0) ? " justify-content-between" : "";

        const isEnglish = this.context.isEnglish;

        let textContent = this.props.text;
        if (isEnglish && this.props.textEnglish !== undefined) {
            textContent = this.props.textEnglish;
        }
        return (
            <div className={"row" + justifyContentProperty}>
                <div className={textColumnClassName}>
                    {this.props.title ? <h3 className={"text-primary"}>{this.props.title}</h3> : null}
                    <p className={"text-content text-primary"} dangerouslySetInnerHTML={{
                        __html: Autolinker.link(textContent.reduce((previous, current) => {
                            return previous + current;
                        }, ""), {
                            email: true,
                            phone: true,
                            className: "text-link",
                        })
                    }}/>
                </div>
                {this.props.media ?
                    <div className={mediaColumnClassName}>
                        <img className="img-fluid" src={`/images/${this.props.media}`}
                             alt={this.props.mediaAltText}/>
                    </div>
                    : null
                }
            </div>
        );
    }
}
