import React from 'react';

export default class MediaContent extends React.Component {

    render() {
        return (
            <div className="media-content">
                <img className={"img-fluid"} src={`/images/${this.props.media}`} alt={this.props.mediaAltText || ""}/>
            </div>
        );
    }
}
