import React from 'react';
import './App.css';
import {BrowserRouter as Router, Link, Route, Switch, useRouteMatch,} from "react-router-dom";
import Page from "./Page";
import GlobalStateContext from "./GlobalStateContext";
import {FaFacebookF, FaInstagram} from "react-icons/fa";

const globalState = {
    isEnglish: navigator.language.substring(0, 2) === "nl",
};

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: [],
        };

        fetch("/pages.json").then(response => {
            if (response.status !== 200) {
                console.log(response.status, response.statusText);
                return;
            }

            response.json().then(data => {
                data.sort((a, b) => a.id - b.id);
                console.log(data);
                this.setState({
                    pages: data,
                });
            });
        }).catch(function (err) {
            console.log('Fetch Error :', err);
        });
    }

    render() {
        // We need this reversed as the first match will be displayed, and we want the home page to be first
        const reversedPages = this.state.pages.reduceRight(function (previous, current) {
            previous.push(current);
            return previous;
        }, []);

        let isMobile = window.innerWidth < 567;

        return (
            <GlobalStateContext.Provider value={globalState}>
                <div className={"title-bar"}>
                    <a className={"h1"} href={"/"}>
                        <h1 className={"pt-1 text-primary" + (isMobile ? " display-6" : " display-4")}>B&B De Lange Mouwen</h1>
                    </a>
                    <div className={"d-flex"}>
                        <a href={"https://www.facebook.com/DeLangeMouwen"}
                           className={"social mx-sm-3 mx-1"}
                           target={"_blank"}
                           aria-label={"Facebook"}
                           rel="noopener noreferrer">
                            <FaFacebookF/>
                        </a>
                        <a href={"https://www.instagram.com/delangemouwen/"}
                           className={"social mx-sm-3 mx-1"}
                           target={"_blank"}
                           aria-label={"Instagram"}
                           rel="noopener noreferrer">
                            <FaInstagram/>
                        </a>
                    </div>
                </div>
                <Router>
                    <div>
                        <nav className={"navbar bg-primary"} role={"navigation"}>
                            <ul className="nav nav-pills">
                                {this.state.pages.map(page => {
                                    return (
                                        <li className="nav-item" key={page.id}>
                                            <PageLink className="nav-link"
                                                      to={`/${page.url.trim()}`}
                                                      label={page.title}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>

                        <Switch>
                            {reversedPages.map((page, index) => {
                                return <Route key={index} path={`/${page.url.trim()}`}>
                                    <Page content={page.content}/>
                                </Route>
                            })}
                        </Switch>
                    </div>
                </Router>
            </GlobalStateContext.Provider>
        );
    }
}

function PageLink({label, to}) {
    let active = useRouteMatch({
        path: to,
        exact: true
    }) ? "text-primary active bg-secondary" : "text-secondary";

    return (
        <Link className={"nav-link " + active} to={to}>{label}</Link>
    );
}
