import React from 'react';

export default class MediaContent extends React.Component {

    render() {
        return (
            <div className="media-content">
                
            </div>
        );
    }
}
