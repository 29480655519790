import React from 'react';
import AutoplaySlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

export default class TextContent extends React.Component {

    render() {
        return (
            <div className={"row"}>
                <AutoplaySlider
                    className={"carousel"}>
                    {
                        this.props.images.map((src, index) => {
                            return <div key={index} data-src={"/images/" + src}/>
                        })
                    }
                </AutoplaySlider>
            </div>
        );
    }

}
