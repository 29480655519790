import React from 'react';
import TextContent from "./content/TextContent";
import MediaContent from "./content/MediaContent";
import Carousel from "./content/Carousel";
import ReviewContent from "./content/ReviewContent";

export default class Page extends React.Component {

    render() {
        return (
            <div className={"container pt-4"}>
                {this.props.content.map((content, index) => {
                    if (content.type === "text") {
                        return <TextContent
                            key={index}
                            title={content.title}
                            text={content.text}
                            textEnglish={content.textEnglish}
                            media={content.media}
                            textColumnWidth={content.textColumnWidth}
                            mediaColumnWidth={content.mediaColumnWidth}
                            mediaAltText={content.mediaAltText}
                        />;
                    }
                    if (content.type === "map") {
                        return (
                            <div key={index} className={"row"}>
                                <div className={"col"}>
                                    <iframe frameBorder="0" style={{
                                        border: "0",
                                        height: "400px",
                                        width: "100%",
                                        marginTop: "1rem"
                                    }}
                                            title={"map"}
                                            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJJ-c418eKxkcRa6L13RQ4PP0&key=AIzaSyC1_nfvESOgkjFbjcwUkAwto-B1cUKefBE"
                                            allowFullScreen/>
                                </div>
                            </div>
                        );
                    }
                    if (content.type === "carousel") {
                        return <Carousel key={index} images={content.images}/>
                    }
                    if (content.type === "media") {
                        return <MediaContent key={index} media={content.media}
                                             mediaAltText={content.mediaAltText}/>;
                    }

                    if (content.type === "review") {
                        return <ReviewContent key={index}/>
                    }

                    return null;
                })}
            </div>);
    }
}
